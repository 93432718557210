@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth !important;
}

/* It makes the HTML progress bar filling smooth when value change. */
progress::-webkit-progress-value {
  transition: 0.6s width ease-out;
}

@layer base {
  .btn-gradient {
    @apply !bg-gradient !bg-[length:300%_300%] hover:saturate-[1.2] shadow duration-100 !border-0 !border-transparent !bg-transparent animate-shimmer disabled:!bg-none disabled:!bg-gray-500/30  !text-white;
  }
  .btn {
    @apply !capitalize;
  }
}

@layer utilities {
  .pause {
    animation-play-state: paused;
  }
}
